<template>
  <div>
    <breadcrumb>
      <el-button
        slot="right"
        type="primary"
        v-if="isTeach"
        @click="$handleRoute('teachingCourseTeach')"
      >
        教师管理
      </el-button>
    </breadcrumb>
    <el-card class="table-box">
      <com-table
        ref="table"
        :data="{
          url: '/xapi/course.course/teachList',
          params: { course_id },
        }"
        :columns="[
          { label: '序号', type: 'index', align: 'center' },

          { prop: 'headimg', label: '头像', slot: 'avatar', align: 'center' },
          { prop: 'nickname', label: '教师姓名', align: 'center' },
          { prop: 'phone', label: '教师电话', align: 'center' },
          {
            prop: 'teach_course_list',
            label: '课程名称',
            align: 'center',
            slot: 'coursename',
          },
          {
            prop: 'last_work',
            label: '所属单位',
            align: 'center',
          },
          {
            prop: 'description',
            label: '教师简介',
            slot: 'description',
            align: 'center',
          },
        ]"
        :page-size="10"
      >
        <template slot="description" slot-scope="{ row }">
          <span v-html="row.description"></span>
        </template>
        <template slot="coursename" slot-scope="{ row }">
          <div v-for="(v, i) in row.teach_course_list" :key="i">{{v.course_name}}</div>
        </template>
        <!-- <div style="padding: 0 10px;margin-bottom: 15px;" slot-scope="{row}">
          <el-card :body-style="{ padding: 0 }" :style="{borderRadius: '15px 15px 0 0',cursor:'pointer',width:'100%'}">
            <div @click="$handleRoute({student_user_id:row.id},'me')">
              <el-image
                  fit="contain"
                  :src="row.headimg"
                  style="width:100%;height:165px;"
              >
              </el-image>
              <div style="padding:10px 20px;line-height: 30px;">
                <div style="display: flex"><h3>{{ row.nickname }}</h3>
                  <div style="font-size: 14px;line-height: 30px;">({{ row.phone}})</div>
                </div>
                <p class="ellipsis" v-if="row.description">
                  <span v-html="row.description"></span>
                </p>
                <p class="font-color" :style="{'--color-black':'var(--color-gray)'}" v-else> 暂无 </p>
              </div>
            </div>
          </el-card>
        </div> -->
      </com-table>
    </el-card>
  </div>
</template>

<script>
export default {
  props: ["course_id"],
  data() {
    return {
      isTeach: false,
      courseInfo: {},
    };
  },
  methods: {
    routeUpdate() {
      this.getDetail();
    },
    getDetail() {
      this.loading = true;
      this.$http
        .post("/xapi/course.course/detail", { id: this.course_id })
        .then(({ data }) => {
          this.courseInfo = data;
          this.isTeach = !!data.isTeach;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
</style>