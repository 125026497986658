var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('breadcrumb',[(_vm.isTeach)?_c('el-button',{attrs:{"slot":"right","type":"primary"},on:{"click":function($event){return _vm.$handleRoute('teachingCourseTeach')}},slot:"right"},[_vm._v(" 教师管理 ")]):_vm._e()],1),_c('el-card',{staticClass:"table-box"},[_c('com-table',{ref:"table",attrs:{"data":{
        url: '/xapi/course.course/teachList',
        params: { course_id: _vm.course_id },
      },"columns":[
        { label: '序号', type: 'index', align: 'center' },

        { prop: 'headimg', label: '头像', slot: 'avatar', align: 'center' },
        { prop: 'nickname', label: '教师姓名', align: 'center' },
        { prop: 'phone', label: '教师电话', align: 'center' },
        {
          prop: 'teach_course_list',
          label: '课程名称',
          align: 'center',
          slot: 'coursename',
        },
        {
          prop: 'last_work',
          label: '所属单位',
          align: 'center',
        },
        {
          prop: 'description',
          label: '教师简介',
          slot: 'description',
          align: 'center',
        },
      ],"page-size":10},scopedSlots:_vm._u([{key:"description",fn:function({ row }){return [_c('span',{domProps:{"innerHTML":_vm._s(row.description)}})]}},{key:"coursename",fn:function({ row }){return _vm._l((row.teach_course_list),function(v,i){return _c('div',{key:i},[_vm._v(_vm._s(v.course_name))])})}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }